// Lazyload video or iframe when content clicked

(function( $ ) {
	
	let $lazyFrames = $('[data-lazy-frame]');

	function lazyLoadFrame( $target ) {
		let $frame = $($target.data('lazy-frame'));

		$target.find('img').remove();
		$target.append($frame);

		$target.removeAttr('data-lazy-frame');
		$target.removeAttr('data-lazy-frame-type');
		$target.find('button').remove();
	}

	if ( $lazyFrames.length ) {
		$lazyFrames.click(function(event) {
			let $target = $(event.target).closest('[data-lazy-frame]');

			lazyLoadFrame($target);

			$lazyFrames = $lazyFrames.not($target);
		});
	}

	// make available globally
	window.lazyLoadFrame = lazyLoadFrame;

})( jQuery );
