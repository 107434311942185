(function( $ ) {
	

	// Nav states
    /**
    * States:
    *     All pages have solid background header
    *     Pages have different colour fills
    * 
    *     When scrolling down, hide until scrolled back up 
    */
    (function( $ ) {
        let $siteHeader = $('.site-header');
        let persistAction = '';

        function storeHeaderHeight() {
        	$('body').css('--header-height', $siteHeader.outerHeight(false));
        }

        function updateState( action ) {
            $siteHeader.attr('data-state', determineState(action));
        }

        function determineState( action ) {
            let state = [];
            let scrollThreshold = 100;

            if (
                $(window).scrollTop() > scrollThreshold &&
                'scroll-down' === action
            ) {
                state.push('off-canvas');
            }

            return state.length > 0 ? state.join(' ') : 'default';
        }

        // set intial state
        let scrollDelta = 0;
        let scrollTop = $(window).scrollTop();

        updateState();
        storeHeaderHeight();

        $(window).on('scroll.headerController', function(event) {
            let action = '';

            scrollDelta = $(window).scrollTop() - scrollTop;

            if ( scrollDelta > 0 ) {
                action = 'scroll-down';
            }

            scrollTop = $(window).scrollTop();
            scrollDelta = 0;

            updateState(action);
        });

        $(window).on('resize.headerController', debounce(function(event) {
            storeHeaderHeight();
        }, 150));
       
    })( jQuery );

})( jQuery );
