(function( $ ) {

	/**
	 * Init simpleParallax plugin for images
	 */

	var $images = document.querySelectorAll('.has-parallax');
	new simpleParallax($images, {
	    delay: 0,
	    orientation: 'down',
	    scale: 1.2,
	    overflow: false
	});
	
})( jQuery );
