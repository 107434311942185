( function( $, window, document, undefined ) {

	let $toggle = $('.js-site-nav-toggle');

	const IS_DESKTOP_MQ = '(min-width: 1101px)';

	// Add default attr
	$toggle.attr('aria-expanded', 'false');

	function open() {
		$toggle.attr('aria-expanded', 'true');
		$toggle.addClass('is-active');

		$('body').addClass('nav-is-open');
		
		bodyScrollLock.disableBodyScroll(document.querySelector('.js-site-nav-list'), {reserveScrollBarGap: true});

		$(document).on('keydown.mobile_nav', (event) => {
			if ( 'Escape' !== event.key ) {
				return;
			}

			close();

			event.preventDefault();
			return false;
		});
	}

	function close() {
		$toggle.attr('aria-expanded', 'false');
		$toggle.removeClass('is-active');

		$('body').removeClass('nav-is-open');

		bodyScrollLock.enableBodyScroll(document.querySelector('.js-site-nav-list'));

		$(document).off('.mobile_nav');
	}

	function is_open() {
		return $toggle.is('[aria-expanded="true"]');
	}

	function toggle() {
		if ( is_open() ) {
			close();
		} else {
			open();
		}
	}


	$toggle.on('click', (event) => {
		toggle();

		event.preventDefault();
		return false;
	});	

	$(window).on('resize', throttle( () => {
		if ( is_open() && window.matchMedia(IS_DESKTOP_MQ).matches ) {
			close();
		}
	}, 150 ));
	
} )( jQuery, window, document );
