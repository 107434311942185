// IFrame Group block switcher

( function( $, window, document, undefined ) {
	const $toggleButtons = $('.js-view-toggle');
	const $views = $('.js-view');

	/**
	 * Switch to another view.
	 * @param  {string} viewID The ID for a view element.
	 */
	function switchView( viewID ) {
		const $view = $( viewID );

		if ( $view.length ) {
			// Hide other views
			$views.addClass('is-hidden');
			$toggleButtons.attr('aria-pressed', 'false');

			$view.removeClass('is-hidden');
			$( `[data-target="${viewID}"]` ).attr('aria-pressed', 'true');
		}
	}


	if ( $toggleButtons.length && $views.length ) {

		let currentViewId = $toggleButtons.filter('[aria-pressed]').data('target');
		switchView( currentViewId );

		$toggleButtons.on( 'click touchend', (event) => {
			let $target = $(event.target);

			if ( !$target.is('.js-view-toggle') ) {
				$target = $target.closest('.js-view-toggle');
			}

			switchView( $target.data('target') );

			event.preventDefault();
			event.stopPropagation();
			return false;
		} );

	}
	
} )( jQuery, window, document );
