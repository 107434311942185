
(function( $ ) {
	
	let $scrollButtons = $('.js-scroll-timeline');

	if ( !$scrollButtons.length ) {
		return;
	}

	$scrollButtons.click(function(event) {
		let $target = $( $(this).data('target') );

		if ( !$target.length ) {
			return;
		}

		let dir = parseInt( $(this).data('dir') );
		let newScrollLeft = $target[0].scrollLeft + $target[0].scrollWidth / 4 * dir;

		if ( newScrollLeft < 100 ) {
			newScrollLeft = 0;
		}

		// if 100px from the end of the scroll track
		// can't set var to .scrollWidth as it ends up moving too fast
		if ( newScrollLeft > $target[0].scrollWidth - $target[0].offsetWidth - 100 ) {
			newScrollLeft += 100;
		}

		$target.animate({
			scrollLeft: newScrollLeft
		}, {
			duration: 350
		});

		event.preventDefault();
		return false;
	});

})( jQuery );
